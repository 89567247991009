































































































































/* eslint-disable @typescript-eslint/explicit-member-accessibility, @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any */
import { Vue, Watch } from 'vue-property-decorator'
import Component from 'vue-class-component'
import PageTab from '@/components/ui/PageTab.vue'
import { Debounce } from 'lodash-decorators'
import { vxm } from '@/store'

type Shop = {
  id: number
  title: string
}

type Brand = {
  id: number
  name: string
}

type ColumnConfig = {
  label1: string
  label2: string
  placeholder: string
  rank: {
    [key: string]: number[]
  }
}

@Component({
  components: {
    PageTab,
  },
})
export default class TopBrandsConfig extends Vue {
  $refs: Vue['$refs'] & {
    brandsConfigForm: {
      validate: any
      reset: any
    }
  }

  private loading = true
  private configLoading = false
  private saveLoading = false

  private shops: Shop[] = []
  private brands: Brand[] = []
  private selectedShopId: number | null = null
  private includeBrands: number[] = []
  private excludeBrands: number[] = []

  private brandsConfig: { [key: number]: ColumnConfig } = {
    1: { label1: '', label2: '', placeholder: '', rank: {} },
    2: { label1: '', label2: '', placeholder: '', rank: {} },
    3: { label1: '', label2: '', placeholder: '', rank: {} },
  }

  private selectedBrand: { [key: number]: number | null } = {
    1: null,
    2: null,
    3: null,
  }

  private selectedRank: { [key: number]: string } = {
    1: '1',
    2: '1',
    3: '1',
  }

  private rules = {
    shopId: [(v: any) => !!v || this.$t('c:validation:This field is required')],
  }

  private async mounted() {
    try {
      const shopsResponse = await this.$axios.get('/v4/site/get-webshops')
      this.shops = shopsResponse.data.data.map((shop: any) => ({
        ...shop,
      }))
      this.loading = false
    } catch (err) {
      await vxm.alert.onAxiosError(err, 'Error loading page')
    }
  }

  private resetConfig() {
    this.brandsConfig = {
      1: { label1: '', label2: '', placeholder: '', rank: {} },
      2: { label1: '', label2: '', placeholder: '', rank: {} },
      3: { label1: '', label2: '', placeholder: '', rank: {} },
    }
  }

  private async loadShopConfig() {
    if (!this.selectedShopId) {
      this.resetConfig()
      return
    }

    this.configLoading = true
    try {
      const response = await this.$axios.get(`/v4/site/webshop/top-brands-config/${this.selectedShopId}`)
      if (response.data.data) {
        const configData =
          typeof response.data.data.topBrandsConfig === 'string'
            ? JSON.parse(response.data.data.topBrandsConfig)
            : response.data.data.topBrandsConfig

        this.includeBrands = response.data.data.includeBrands
        this.excludeBrands = response.data.data.excludeBrands
        this.brands = response.data.data.brands

        if (this.validateConfigData(configData)) {
          this.brandsConfig = configData
        } else {
          vxm.alert.warning({
            content: 'Cannot load configuration, try setup a new one',
            title: 'Warning',
          })
          this.resetConfig()
        }
      } else {
        this.resetConfig()
      }
    } catch (err) {
      await vxm.alert.onAxiosError(err, 'Error loading brand configuration')
      this.resetConfig()
    } finally {
      this.configLoading = false
    }
  }

  private getBrandName(brandId: number): string {
    const brand = this.brands.find((b) => b.id === brandId)
    return brand ? brand.name : `Brand ID: ${brandId}`
  }

  // Get all currently selected brands across all columns and ranks
  private getAllSelectedBrands(): number[] {
    const selectedBrands: number[] = []

    // Add the currently selected brands in the dropdowns (except for the current column)
    Object.keys(this.selectedBrand).forEach((key) => {
      const columnNum = parseInt(key)
      const brand = this.selectedBrand[columnNum]
      if (brand !== null) {
        selectedBrands.push(brand)
      }
    })

    // Add all brands that are already added to columns
    for (let i = 1; i <= 3; i++) {
      const column = this.brandsConfig[i]
      Object.values(column.rank).forEach((brands) => {
        brands.forEach((brandId) => {
          selectedBrands.push(brandId)
        })
      })
    }

    return selectedBrands
  }

  // Get available brands for a specific column dropdown
  private getAvailableBrandsForColumn(columnNum: number): Brand[] {
    // Get all selected brands except the one currently selected in this dropdown
    const selectedBrands = this.getAllSelectedBrands()
    const currentSelection = this.selectedBrand[columnNum]

    // Filter out brands that are already selected in other dropdowns or columns
    return this.brands.filter((brand) => {
      // Include the current selection for this dropdown
      if (currentSelection === brand.id) {
        return true
      }

      // Check if this brand is already selected elsewhere
      return !selectedBrands.includes(brand.id)
    })
  }

  private addBrandToColumn(columnNum: number) {
    const brandId = this.selectedBrand[columnNum]
    const rank = this.selectedRank[columnNum]

    if (!brandId || !rank) return

    // Ensure the rank exists in the configuration
    if (!this.brandsConfig[columnNum].rank[rank]) {
      this.$set(this.brandsConfig[columnNum].rank, rank, [])
    }

    // Check if brand is already in this rank
    if (!this.brandsConfig[columnNum].rank[rank].includes(brandId)) {
      this.brandsConfig[columnNum].rank[rank].push(brandId)
    }

    // Reset selection fields
    this.selectedBrand[columnNum] = null
    this.selectedRank[columnNum] = '1'
  }

  private removeBrand(columnNum: number, rank: string, brandId: number) {
    const brandIndex = this.brandsConfig[columnNum].rank[rank].indexOf(brandId)
    if (brandIndex >= 0) {
      this.brandsConfig[columnNum].rank[rank].splice(brandIndex, 1)

      // Remove the rank if empty
      if (this.brandsConfig[columnNum].rank[rank].length === 0) {
        this.$delete(this.brandsConfig[columnNum].rank, rank)
      }
    }
  }

  private async saveConfig() {
    if (!this.selectedShopId) return

    this.saveLoading = true
    try {
      await this.$axios.post(`/v4/site/webshop/top-brands-config/${this.selectedShopId}`, {
        data: JSON.stringify(this.brandsConfig),
      })
      vxm.alert.success({
        content: this.$t('c:common:Success') as string,
        title: this.$t('c:common:Success') as string,
      })
    } catch (err) {
      await vxm.alert.onAxiosError(err, 'Error saving brand configuration')
    } finally {
      this.saveLoading = false
    }
  }

  private validateConfigData(data: any): boolean {
    // Check if data is not null or undefined
    if (!data) return false

    // Check if the required columns exist
    if (!data[1] || !data[2] || !data[3]) return false

    // Check if each column has the required properties
    for (let i = 1; i <= 3; i++) {
      const column = data[i]

      // Check if labels property exists (it can be empty string)
      if (!column.hasOwnProperty('label1')) return false
      if (!column.hasOwnProperty('label2')) return false
      if (!column.hasOwnProperty('placeholder')) return false

      // Check if rank property exists and is an object
      if (!column.hasOwnProperty('rank') || typeof column.rank !== 'object') return false

      // Check each rank entry to ensure it contains arrays of numbers
      for (const rankKey in column.rank) {
        const rankValue = column.rank[rankKey]

        // Check if rankValue is an array
        if (!Array.isArray(rankValue)) return false

        // Check if all elements in the array are numbers
        for (const brandId of rankValue) {
          if (typeof brandId !== 'number') return false
        }
      }
    }

    return true
  }
}
